<template>
  <svg
    width="302"
    height="271"
    viewBox="0 0 302 271"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_501_2)">
      <path
        d="M154.664 0.590984L144.837 3.12465L23.7722 34.3399L13.9456 36.8736C9.17598 38.1092 5.09123 41.1859 2.58758 45.4289C0.0839288 49.6719 -0.634164 54.7346 0.590843 59.5063L43.2009 224.712C44.4366 229.481 47.5139 233.565 51.7575 236.069C56.0012 238.572 61.0647 239.29 65.8372 238.065L65.8625 238.059L206.53 201.789L206.555 201.782C211.325 200.547 215.41 197.47 217.913 193.227C220.417 188.984 221.135 183.921 219.91 179.15L177.3 13.9438C176.064 9.17483 172.987 5.09072 168.744 2.58745C164.5 0.0841746 159.436 -0.63382 154.664 0.590984Z"
        fill="#F2F2F2"
      />
      <path
        d="M156 5.77044L144.361 8.7714L26.9203 39.0522L15.2816 42.0531C11.885 42.9329 8.97615 45.124 7.19322 48.1455C5.4103 51.167 4.89893 54.7723 5.77128 58.1704L48.3813 223.376C49.2613 226.772 51.4527 229.681 54.4747 231.463C57.4967 233.246 61.1026 233.757 64.5012 232.885L64.5265 232.879L205.194 196.609L205.22 196.602C208.616 195.723 211.525 193.532 213.308 190.51C215.091 187.489 215.602 183.883 214.73 180.485L172.12 15.2793C171.24 11.8832 169.048 8.97487 166.026 7.19223C163.004 5.4096 159.399 4.89827 156 5.77044Z"
        fill="white"
      />
      <path
        d="M147.212 59.2686L76.0514 77.6165C75.2566 77.8214 74.4129 77.7023 73.706 77.2853C72.9991 76.8682 72.4868 76.1875 72.2818 75.3929C72.0769 74.5982 72.1961 73.7547 72.6131 73.0479C73.0302 72.3411 73.711 71.8289 74.5058 71.6239L145.666 53.2761C146.461 53.072 147.304 53.1916 148.01 53.6087C148.716 54.0257 149.228 54.7061 149.433 55.5003C149.638 56.2945 149.519 57.1376 149.102 57.8442C148.686 58.5507 148.006 59.0631 147.212 59.2686Z"
        fill="#F2F2F2"
      />
      <path
        d="M162.181 66.1936L78.6596 87.7288C77.8649 87.9337 77.0212 87.8146 76.3143 87.3976C75.6073 86.9805 75.0951 86.2998 74.8901 85.5052C74.6851 84.7105 74.8043 83.867 75.2214 83.1602C75.6384 82.4534 76.3193 81.9412 77.114 81.7362L160.636 60.2011C161.431 59.9961 162.274 60.1153 162.981 60.5323C163.688 60.9493 164.2 61.63 164.405 62.4246C164.61 63.2193 164.491 64.0628 164.074 64.7696C163.657 65.4765 162.976 65.9887 162.181 66.1936Z"
        fill="#F2F2F2"
      />
      <path
        d="M158.9 104.587L87.7401 122.935C87.3466 123.036 86.9369 123.059 86.5345 123.003C86.132 122.946 85.7448 122.81 85.3947 122.604C85.0447 122.397 84.7388 122.124 84.4944 121.799C84.25 121.475 84.072 121.105 83.9705 120.711C83.8691 120.318 83.8461 119.908 83.9029 119.506C83.9598 119.104 84.0953 118.716 84.3018 118.366C84.5083 118.016 84.7818 117.711 85.1065 117.466C85.4313 117.222 85.8009 117.044 86.1945 116.942L157.355 98.5946C157.748 98.4931 158.158 98.4702 158.56 98.527C158.963 98.5838 159.35 98.7193 159.7 98.9258C160.05 99.1323 160.356 99.4057 160.6 99.7304C160.845 100.055 161.023 100.425 161.124 100.818C161.226 101.212 161.249 101.621 161.192 102.024C161.135 102.426 161 102.813 160.793 103.163C160.586 103.513 160.313 103.819 159.988 104.063C159.664 104.308 159.294 104.486 158.9 104.587Z"
        fill="#F2F2F2"
      />
      <path
        d="M173.87 111.512L90.3483 133.048C89.9548 133.149 89.5451 133.172 89.1427 133.115C88.7402 133.058 88.353 132.923 88.0029 132.716C87.6529 132.51 87.347 132.237 87.1026 131.912C86.8582 131.587 86.6802 131.218 86.5788 130.824C86.4773 130.431 86.4543 130.021 86.5111 129.619C86.568 129.216 86.7035 128.829 86.91 128.479C87.1165 128.129 87.39 127.823 87.7147 127.579C88.0395 127.335 88.4092 127.157 88.8027 127.055L172.325 105.52C173.119 105.315 173.963 105.434 174.67 105.851C175.377 106.268 175.889 106.949 176.094 107.744C176.299 108.538 176.18 109.382 175.763 110.089C175.346 110.795 174.665 111.308 173.87 111.512Z"
        fill="#F2F2F2"
      />
      <path
        d="M170.589 149.906L99.4288 168.253C98.6342 168.458 97.791 168.338 97.0846 167.921C96.3781 167.504 95.8661 166.824 95.6612 166.029C95.4564 165.235 95.5753 164.392 95.992 163.685C96.4086 162.979 97.0889 162.466 97.8832 162.261L169.043 143.913C169.838 143.708 170.682 143.827 171.389 144.244C172.096 144.661 172.608 145.342 172.813 146.137C173.018 146.931 172.899 147.775 172.482 148.482C172.065 149.188 171.384 149.701 170.589 149.906Z"
        fill="#F2F2F2"
      />
      <path
        d="M185.559 156.831L102.037 178.366C101.643 178.468 101.233 178.491 100.831 178.435C100.428 178.378 100.04 178.243 99.6899 178.036C99.3396 177.83 99.0334 177.556 98.7888 177.231C98.5443 176.907 98.3661 176.537 98.2646 176.143C98.163 175.749 98.1401 175.34 98.197 174.937C98.254 174.534 98.3898 174.147 98.5965 173.797C98.8033 173.447 99.0771 173.141 99.4022 172.897C99.7273 172.652 100.097 172.475 100.491 172.373L184.013 150.838C184.808 150.633 185.652 150.752 186.358 151.169C187.065 151.587 187.578 152.267 187.783 153.062C187.988 153.857 187.868 154.7 187.451 155.407C187.034 156.114 186.353 156.626 185.559 156.831Z"
        fill="#F2F2F2"
      />
      <path
        d="M63.9033 96.4295L38.7203 102.923C38.3393 103.02 37.9349 102.963 37.5961 102.763C37.2572 102.563 37.0115 102.237 36.9128 101.856L31.1321 79.4437C31.0343 79.0627 31.0916 78.6584 31.2916 78.3196C31.4915 77.9808 31.8176 77.7351 32.1985 77.6365L57.3814 71.1433C57.7625 71.0455 58.1668 71.1028 58.5057 71.3027C58.8445 71.5026 59.0903 71.8287 59.1889 72.2095L64.9697 94.6223C65.0675 95.0033 65.0101 95.4076 64.8102 95.7464C64.6103 96.0852 64.2841 96.3309 63.9033 96.4295Z"
        fill="#E6E6E6"
      />
      <path
        d="M75.592 141.748L50.409 148.241C50.028 148.339 49.6236 148.281 49.2848 148.082C48.9459 147.882 48.7002 147.556 48.6015 147.175L42.8208 124.762C42.723 124.381 42.7803 123.977 42.9803 123.638C43.1802 123.299 43.5063 123.053 43.8872 122.955L69.0701 116.462C69.4512 116.364 69.8555 116.421 70.1944 116.621C70.5332 116.821 70.779 117.147 70.8776 117.528L76.6583 139.941C76.7561 140.322 76.6988 140.726 76.4989 141.065C76.299 141.404 75.9728 141.649 75.592 141.748Z"
        fill="#E6E6E6"
      />
      <path
        d="M87.2806 187.067L62.0977 193.56C61.7166 193.658 61.3123 193.6 60.9734 193.4C60.6346 193.201 60.3889 192.874 60.2902 192.494L54.5095 170.081C54.4117 169.7 54.469 169.296 54.6689 168.957C54.8688 168.618 55.195 168.372 55.5759 168.274L80.7588 161.781C81.1399 161.683 81.5442 161.74 81.8831 161.94C82.2219 162.14 82.4676 162.466 82.5663 162.847L88.347 185.26C88.4448 185.641 88.3875 186.045 88.1876 186.384C87.9877 186.722 87.6615 186.968 87.2806 187.067Z"
        fill="#E6E6E6"
      />
      <path
        d="M252.808 42.4683H107.486C102.559 42.4738 97.835 44.4333 94.3509 47.9168C90.8669 51.4002 88.9071 56.1233 88.9016 61.0497V231.661C88.9071 236.587 90.8669 241.31 94.3509 244.793C97.835 248.277 102.559 250.236 107.486 250.242H252.808C257.735 250.236 262.459 248.277 265.943 244.793C269.427 241.31 271.387 236.587 271.392 231.661V61.0497C271.387 56.1233 269.427 51.4002 265.943 47.9168C262.459 44.4333 257.735 42.4738 252.808 42.4683Z"
        fill="#E6E6E6"
      />
      <path
        d="M252.808 47.8179H107.486C103.977 47.8218 100.613 49.2172 98.1323 51.6978C95.6512 54.1785 94.2556 57.5419 94.2517 61.0501V231.661C94.2556 235.169 95.6512 238.532 98.1323 241.013C100.613 243.494 103.977 244.889 107.486 244.893H252.808C256.317 244.889 259.681 243.494 262.162 241.013C264.643 238.532 266.038 235.169 266.042 231.661V61.0501C266.038 57.5419 264.643 54.1786 262.162 51.6979C259.681 49.2172 256.317 47.8218 252.808 47.8179Z"
        fill="white"
      />
      <path
        d="M230.937 141.568H157.449C157.042 141.568 156.639 141.489 156.263 141.333C155.887 141.178 155.546 140.95 155.258 140.663C154.97 140.376 154.742 140.034 154.586 139.659C154.431 139.283 154.35 138.88 154.35 138.474C154.35 138.067 154.431 137.664 154.586 137.289C154.742 136.913 154.97 136.572 155.258 136.284C155.546 135.997 155.887 135.769 156.263 135.614C156.639 135.459 157.042 135.379 157.449 135.379H230.937C231.757 135.38 232.543 135.707 233.123 136.287C233.703 136.867 234.028 137.654 234.028 138.474C234.028 139.294 233.703 140.08 233.123 140.66C232.543 141.241 231.757 141.567 230.937 141.568Z"
        fill="#E6E6E6"
      />
      <path
        d="M243.703 152.011H157.449C157.042 152.011 156.639 151.932 156.263 151.776C155.887 151.621 155.546 151.393 155.258 151.106C154.97 150.818 154.742 150.477 154.586 150.101C154.431 149.726 154.35 149.323 154.35 148.917C154.35 148.51 154.431 148.107 154.586 147.732C154.742 147.356 154.97 147.015 155.258 146.727C155.546 146.44 155.887 146.212 156.263 146.057C156.639 145.902 157.042 145.822 157.449 145.822H243.703C244.524 145.822 245.311 146.148 245.891 146.729C246.472 147.309 246.798 148.096 246.798 148.917C246.798 149.737 246.472 150.524 245.891 151.105C245.311 151.685 244.524 152.011 243.703 152.011Z"
        fill="#E6E6E6"
      />
      <path
        d="M230.937 188.369H157.449C157.042 188.37 156.639 188.29 156.263 188.135C155.887 187.979 155.546 187.752 155.258 187.464C154.97 187.177 154.742 186.836 154.586 186.46C154.431 186.084 154.35 185.682 154.35 185.275C154.35 184.868 154.431 184.466 154.586 184.09C154.742 183.714 154.97 183.373 155.258 183.086C155.546 182.798 155.887 182.571 156.263 182.415C156.639 182.26 157.042 182.18 157.449 182.181H230.937C231.758 182.181 232.545 182.507 233.125 183.087C233.706 183.667 234.032 184.454 234.032 185.275C234.032 186.096 233.706 186.883 233.125 187.463C232.545 188.043 231.758 188.369 230.937 188.369Z"
        fill="#E6E6E6"
      />
      <path
        d="M243.703 198.812H157.449C157.042 198.813 156.639 198.733 156.263 198.578C155.887 198.422 155.546 198.194 155.258 197.907C154.97 197.62 154.742 197.278 154.586 196.903C154.431 196.527 154.35 196.124 154.35 195.718C154.35 195.311 154.431 194.909 154.586 194.533C154.742 194.157 154.97 193.816 155.258 193.529C155.546 193.241 155.887 193.013 156.263 192.858C156.639 192.703 157.042 192.623 157.449 192.624H243.703C244.11 192.623 244.512 192.703 244.888 192.858C245.264 193.013 245.606 193.241 245.893 193.529C246.181 193.816 246.41 194.157 246.565 194.533C246.721 194.909 246.801 195.311 246.801 195.718C246.801 196.124 246.721 196.527 246.565 196.903C246.41 197.278 246.181 197.62 245.893 197.907C245.606 198.194 245.264 198.422 244.888 198.578C244.512 198.733 244.11 198.813 243.703 198.812Z"
        fill="#E6E6E6"
      />
      <path
        d="M140.987 156.752H114.98C114.587 156.752 114.209 156.595 113.931 156.317C113.653 156.039 113.496 155.662 113.496 155.268V132.122C113.496 131.729 113.653 131.352 113.931 131.074C114.209 130.796 114.587 130.639 114.98 130.639H140.987C141.38 130.639 141.757 130.796 142.036 131.074C142.314 131.352 142.47 131.729 142.471 132.122V155.268C142.47 155.662 142.314 156.039 142.036 156.317C141.757 156.595 141.38 156.752 140.987 156.752Z"
        fill="#E6E6E6"
      />
      <path
        d="M140.987 203.553H114.98C114.587 203.552 114.209 203.396 113.931 203.118C113.653 202.84 113.496 202.463 113.496 202.069V178.923C113.496 178.53 113.653 178.153 113.931 177.875C114.209 177.596 114.587 177.44 114.98 177.439H140.987C141.38 177.44 141.757 177.596 142.036 177.875C142.314 178.153 142.47 178.53 142.471 178.923V202.069C142.47 202.463 142.314 202.84 142.036 203.118C141.757 203.396 141.38 203.552 140.987 203.553Z"
        fill="#E6E6E6"
      />
      <path
        d="M231.018 89.4689H176.838C176.018 89.4689 175.231 89.1429 174.65 88.5626C174.07 87.9823 173.744 87.1952 173.744 86.3746C173.744 85.5539 174.07 84.7669 174.65 84.1866C175.231 83.6063 176.018 83.2803 176.838 83.2803H231.018C231.839 83.2803 232.626 83.6063 233.207 84.1866C233.787 84.7669 234.113 85.5539 234.113 86.3746C234.113 87.1952 233.787 87.9823 233.207 88.5626C232.626 89.1429 231.839 89.4689 231.018 89.4689Z"
        fill="#CCCCCC"
      />
      <path
        d="M243.784 99.9122H176.838C176.432 99.9122 176.03 99.8322 175.654 99.6767C175.279 99.5212 174.938 99.2933 174.65 99.0059C174.363 98.7186 174.135 98.3775 173.979 98.0021C173.824 97.6266 173.744 97.2243 173.744 96.8179C173.744 96.4116 173.824 96.0092 173.979 95.6338C174.135 95.2584 174.363 94.9173 174.65 94.6299C174.938 94.3426 175.279 94.1147 175.654 93.9592C176.03 93.8037 176.432 93.7236 176.838 93.7236H243.784C244.605 93.7236 245.392 94.0496 245.973 94.6299C246.553 95.2102 246.879 95.9973 246.879 96.8179C246.879 97.6386 246.553 98.4256 245.973 99.0059C245.392 99.5862 244.605 99.9122 243.784 99.9122Z"
        fill="#CCCCCC"
      />
      <path
        d="M164.848 112.568H114.898C114.505 112.568 114.128 112.411 113.85 112.133C113.571 111.855 113.415 111.478 113.414 111.085V72.1082C113.415 71.7148 113.571 71.3377 113.85 71.0596C114.128 70.7814 114.505 70.6249 114.898 70.6245H164.848C165.241 70.6249 165.618 70.7814 165.896 71.0596C166.175 71.3377 166.331 71.7148 166.332 72.1082V111.085C166.331 111.478 166.175 111.855 165.896 112.133C165.618 112.411 165.241 112.568 164.848 112.568Z"
        :fill="getAssets.primaryBrandColor || '#000000'"
      />
    </g>
    <defs>
      <clipPath id="clip0_501_2">
        <rect width="302" height="271" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Submit-App-Svg",
  computed: {
    ...mapGetters(["getAssets"]),
  },
};
</script>
